import { payload } from "app/utils/types";
import {
  GET_PLAN_FINALIZATION,
  fetchPlanFinalizationStart,
  fetchPlanFinalizationSuccess,
  fetchPlanFinalizationError,
  SEND_DOC_TO_AGENT,
  sendDataToAgentStart,
  sendDataToAgentSuccess,
  sendDataToAgentError,
  VERIFY_PROPOSAL,
  verifyProposalStart,
  verifyProposalSuccess,
  verifyProposalError,
  REJECT_PROPOSAL,
  rejectProposalStart,
  rejectProposalSuccess,
  rejectProposalError,
  VERIFY_DELIVERY,
  verifyDeliveryStart,
  verifyDeliverySuccess,
  verifyDeliveryError,
  APPROVE_AGREEMENT,
  APPROVE_ORIGINAL_AGREEMENT,
  approveAgreementStart,
  approveAgreementSuccess,
  approveAgreementError,
  REJECT_AGREEMENT,
  rejectAgreementStart,
  rejectAgreementSuccess,
  rejectAgreementError,
  updateOverallProgress,
  UPDATE_PROPOSAL,
  updateProposalStart,
  updateProposalSuccess,
  updateProposalError,
  clientInvoiceGenerateProposalStart,
  clientInvoiceGenerateProposalSuccess,
  clientInvoiceGenerateProposalError,
  CLIENT_INVOICE_GENERATE_PROPOSAL,
} from "app/redux";
import { makeRequest } from "app/utils/makeRequest";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  approveOriginalAgreementError,
  approveOriginalAgreementStart,
  approveOriginalAgreementSuccess,
} from "app/redux/features/planFinalization";

function* planFinalizationFetchRequest(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(fetchPlanFinalizationStart());
    const response = yield call(() =>
      makeRequest(path).get(queryString, requestParams, queryParams),
    );
    yield put(fetchPlanFinalizationSuccess(response.data));
    yield put(updateOverallProgress(response.data?.overallProgress));
  } catch (error: any) {
    yield put(fetchPlanFinalizationError(error));
  }
}

function* sendDocToAgent(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(sendDataToAgentStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, {}, requestParams, queryParams),
    );
    yield put(sendDataToAgentSuccess(response.data));
  } catch (error: any) {
    yield put(sendDataToAgentError(error));
  }
}

function* verifyProposal(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(verifyProposalStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, {}, requestParams, queryParams),
    );
    yield put(verifyProposalSuccess(response.data));
  } catch (error: any) {
    yield put(verifyProposalError(error));
  }
}

function* rejectProposal(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams, body },
    } = actions;
    yield put(rejectProposalStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, body, requestParams, queryParams),
    );
    yield put(rejectProposalSuccess(response.data));
  } catch (error: any) {
    yield put(rejectProposalError(error));
  }
}

function* verifyDelivery(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(verifyDeliveryStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, {}, requestParams, queryParams),
    );
    yield put(verifyDeliverySuccess(response.data));
  } catch (error: any) {
    yield put(verifyDeliveryError(error));
  }
}

function* approveAgreement(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(approveAgreementStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, {}, requestParams, queryParams),
    );
    yield put(approveAgreementSuccess(response.data));
  } catch (error: any) {
    yield put(approveAgreementError(error));
  }
}
function* approveOriginalAgreement(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(approveOriginalAgreementStart());
    const response = yield call(() =>
      makeRequest(path).patch({}, queryString, requestParams, queryParams),
    );
    yield put(approveOriginalAgreementSuccess(response.data));
  } catch (error: any) {
    yield put(approveOriginalAgreementError(error));
  }
}

function* rejectAgreement(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams, body },
    } = actions;
    yield put(rejectAgreementStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, body, requestParams, queryParams),
    );
    yield put(rejectAgreementSuccess(response.data));
  } catch (error: any) {
    yield put(rejectAgreementError(error));
  }
}

function* updateProposal(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(updateProposalStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, {}, requestParams, queryParams),
    );
    yield put(updateProposalSuccess(response.data));
  } catch (error: any) {
    yield put(updateProposalError(error));
  }
}

function* clientInvoiceGenerateProposal(actions: payload): Generator<any, void, any> {
  try {
    const {
      payload: { address: path, key: queryString, requestParams, queryParams },
    } = actions;
    yield put(clientInvoiceGenerateProposalStart());
    const response = yield call(() =>
      makeRequest(path).put(queryString, {}, requestParams, queryParams),
    );
    yield put(clientInvoiceGenerateProposalSuccess(response.data));
  } catch (error: any) {
    yield put(clientInvoiceGenerateProposalError(error));
  }
}

function* watchPendingSagas() {
  yield takeLatest(GET_PLAN_FINALIZATION, planFinalizationFetchRequest);
  yield takeLatest(SEND_DOC_TO_AGENT, sendDocToAgent);
  yield takeLatest(VERIFY_PROPOSAL, verifyProposal);
  yield takeLatest(REJECT_PROPOSAL, rejectProposal);
  yield takeLatest(VERIFY_DELIVERY, verifyDelivery);
  yield takeLatest(APPROVE_AGREEMENT, approveAgreement);
  yield takeLatest(APPROVE_ORIGINAL_AGREEMENT, approveOriginalAgreement);
  yield takeLatest(REJECT_AGREEMENT, rejectAgreement);
  yield takeLatest(UPDATE_PROPOSAL, updateProposal);
  yield takeLatest(CLIENT_INVOICE_GENERATE_PROPOSAL, clientInvoiceGenerateProposal);
}

export default function* planFinalizationSaga() {
  yield all([watchPendingSagas()]);
}
