import { v4 as uuidv4 } from "uuid";
import {
  AgentCommissionTracking,
  ColumnJson,
  Commission,
  CommissionCalculation,
  DataTableDataInterface,
  notInSystemAgent,
} from "app/utils/types";

export const comissionEditableColumns: string[] = ["appPercentage"];

export const viewPlanRiderComissionCommonColumns = [
  "liquidityRider",
  "commissionablePremium",
  "excessPremium",
];

export const viewPolicyDetailCommissionColumnSchema = (
  label: string,
  field: string,
  isEditing: boolean,
): ColumnJson => ({
  id: `${field}-${uuidv4()}`,
  body: {},
  field,
  label,
  header: false,
  filters: {
    filterType: "text",
    value: "",
  },
  style: {
    width: "33%",
  },
  editColumn: field === "appPercentage" ? isEditing : false,
  align: "right",
  alignHeader: "center",
  editorType: field === "policyReviewCompleted" ? "calendar" : "decimal",
});

export const viewPolicyCommissionDetailsTableDataJson: (
  headers: any[],
  isEditing: boolean,
) => DataTableDataInterface = (headers, isEditing) => ({
  pagination: 10,
  rowsPerPage: [25, 50, 100],
  styles: {
    minWidth: "45rem",
    minHeight: "",
  },
  scrollable: true,
  scrollHeight: "600px",
  onRowClickNavigateTo: "/agent/contact",
  column: headers.map((h) =>
    viewPolicyDetailCommissionColumnSchema(
      h.label,
      h.tableName === "years" ? "year" : h.tableName,
      isEditing,
    ),
  ),
});

export const viewPlanRiderComissionColumnSchema: (isEditing: boolean) => DataTableDataInterface = (
  isEditing,
) => ({
  rowsPerPage: [25, 50, 100],
  styles: {
    minWidth: "50rem",
    minHeight: "",
  },
  scrollable: false,
  scrollHeight: "500px",
  onRowClickNavigateTo: "",
  column: [
    {
      id: uuidv4(),
      body: {},
      field: "liquidityRider",
      label: "Liquidity Rider (Yes/No)",
      header: false,
      editColumn: isEditing,
      align: "center",
      alignHeader: "center",
      editorType: "dropDown",
      style: {
        width: "25%",
        height: 50,
        borderColor: "gray",
        borderWidth: 1,
      },
    },
    {
      id: uuidv4(),
      body: {},
      field: "commissionablePremium",
      label: "Commissionable Premium",
      header: false,
      style: {
        width: "25%",
        height: 50,
        borderColor: "gray",
        borderWidth: 1,
      },
      editColumn: isEditing,
      editorType: "decimal",
      align: "center",
      alignHeader: "center",
    },
    {
      id: uuidv4(),
      field: "excessPremium",
      label: "Excess Premium",
      editorType: "decimal",
      body: {},
      header: false,
      style: {
        width: "25%",
        height: 50,
        borderColor: "gray",
        borderWidth: 1,
      },
      editColumn: isEditing,
      align: "center",
      alignHeader: "center",
    },
  ],
});

const calculateEstimatedCommission = (
  currYearData: Commission,
  currYearCommissionCalculation: CommissionCalculation,
  planRider: {
    liquidityRider: boolean;
    commissionablePremium: number;
    excessPremium: number;
  },
) => {
  let estimatedCommissions = 0;
  const percentageMultiplier = currYearData.appPercentage / 100;
  const percentageExcess = +(currYearCommissionCalculation?.excess || 0) / 100;
  const percentageNoLiquidityRider = +(currYearCommissionCalculation?.noLiquidityRider || 0) / 100;
  const percentageWithLiquidityRider =
    +(currYearCommissionCalculation?.withLiquidityRider || 0) / 100;

  const { liquidityRider, commissionablePremium, excessPremium } = planRider;

  if (liquidityRider) {
    if (+(currYearCommissionCalculation?.withLiquidityRider || 0) === 0) {
      estimatedCommissions =
        percentageMultiplier * percentageExcess * (commissionablePremium + excessPremium);
    } else {
      estimatedCommissions =
        percentageMultiplier * commissionablePremium * percentageWithLiquidityRider +
        percentageMultiplier * excessPremium * percentageExcess;
    }
  } else {
    // eslint-disable-next-line no-lonely-if
    if (+(currYearCommissionCalculation?.noLiquidityRider || 0) === 0) {
      estimatedCommissions =
        percentageMultiplier * percentageExcess * (commissionablePremium + excessPremium);
    } else {
      estimatedCommissions =
        percentageMultiplier * commissionablePremium * percentageNoLiquidityRider +
        percentageMultiplier * excessPremium * percentageExcess;
    }
  }

  return estimatedCommissions;
};

export const prepareCommissionTrackingData = (allData: any, currentData: any, newValue: any) => {
  const { liquidityRider, excessPremium, commissionablePremium } = allData?.planRider ?? {};

  const planCommissions: AgentCommissionTracking[] = allData?.planCommissions;

  // ---------- Task 1 starts here ----------
  // Update the appPercentage for the selected agent, its subAgency and agency for all years
  const selectedAgentTableIdx = planCommissions?.findIndex((table) => table.id === currentData.id);

  const selectedAgentTable = planCommissions[selectedAgentTableIdx];

  if (selectedAgentTable && selectedAgentTable.commission) {
    selectedAgentTable.commission = selectedAgentTable.commission.map((el) => ({
      ...el,
      appPercentage: newValue,
    }));
  }

  const selectedSubAgencyTableIdx = planCommissions?.findIndex(
    (table) => table.agentId === currentData.agentId && table.userType === "subAgency",
  );

  const selectedSubAgencyTable = planCommissions[selectedSubAgencyTableIdx];

  if (selectedSubAgencyTable && selectedSubAgencyTable.commission) {
    selectedSubAgencyTable.commission = selectedSubAgencyTable.commission.map((el) => ({
      ...el,
      appPercentage: newValue,
    }));
  }

  const selectedAgencyTableIdx = planCommissions?.findIndex(
    (table) => table.agentId === currentData.agentId && table.userType === "agency",
  );

  const selectedAgencyTable = planCommissions[selectedAgencyTableIdx];

  if (selectedAgencyTable && selectedAgencyTable.commission) {
    selectedAgencyTable.commission = selectedAgencyTable.commission.map((el) => ({
      ...el,
      appPercentage: newValue,
    }));
  }
  // ---------- Task 1 ends here ----------

  // ---------- Task 2 starts here ----------
  // calculate estimated commission for the selected agent
  selectedAgentTable.commission = selectedAgentTable.commission.map((el) => {
    const correspondingCommissionCalculation = selectedAgentTable.commissionCalculation.find(
      (calculation) => +calculation.year === +el.year,
    );

    const estimatedCommissions = calculateEstimatedCommission(
      el,
      correspondingCommissionCalculation!,
      {
        liquidityRider,
        commissionablePremium,
        excessPremium,
      },
    );

    return { ...el, estimatedCommissions };
  });
  // ---------- Task 2 ends here ----------

  // ---------- Task 3 starts here ----------
  // calculate estimated commission for the selected subAgency
  // DO NOT UPDATE THE modifiedSubAgencyCommissionCalculation TO allData.planCommissions
  let modifiedSubAgencyCommissionCalculation: CommissionCalculation[] = [];
  if (selectedSubAgencyTable) {
    modifiedSubAgencyCommissionCalculation = selectedSubAgencyTable.commissionCalculation.map(
      (el) => {
        const correspondingAgentCommission = selectedAgentTable.commissionCalculation.find(
          (commission) => +commission.year === +el.year,
        );
        return {
          year: el.year,
          excess: Math.max(el.excess - (correspondingAgentCommission?.excess || 0), 0),
          noLiquidityRider: Math.max(
            el.noLiquidityRider - (correspondingAgentCommission?.noLiquidityRider || 0),
            0,
          ),
          withLiquidityRider: Math.max(
            el.withLiquidityRider - (correspondingAgentCommission?.withLiquidityRider || 0),
            0,
          ),
        };
      },
    );

    selectedSubAgencyTable.commission = selectedSubAgencyTable.commission.map((el) => {
      const correspondingCommissionCalculation = modifiedSubAgencyCommissionCalculation.find(
        (calculation) => +calculation.year === +el.year,
      );

      const estimatedCommissions = calculateEstimatedCommission(
        el,
        correspondingCommissionCalculation!,
        {
          liquidityRider,
          commissionablePremium,
          excessPremium,
        },
      );

      return { ...el, estimatedCommissions };
    });
  }
  // --------- Task 3 ends here ----------

  // ---------- Task 4 starts here ----------
  // calculate estimated commission for the selected agency
  // DO NOT UPDATE THE modifiedAgencyCommissionCalculation TO allData.planCommissions
  if (selectedAgencyTable) {
    const modifiedAgencyCommissionCalculation = selectedAgencyTable.commissionCalculation.map(
      (el) => {
        const correspondingSubAgencyCommission = modifiedSubAgencyCommissionCalculation.find(
          (commission) => +commission.year === +el.year,
        );

        const correspondingAgentCommission = selectedAgentTable.commissionCalculation.find(
          (commission) => +commission.year === +el.year,
        );

        return {
          year: el.year,
          excess: Math.max(
            el.excess -
              (correspondingSubAgencyCommission?.excess || 0) -
              (correspondingAgentCommission?.excess || 0),
            0,
          ),
          noLiquidityRider: Math.max(
            el.noLiquidityRider -
              (correspondingSubAgencyCommission?.noLiquidityRider || 0) -
              (correspondingAgentCommission?.noLiquidityRider || 0),
            0,
          ),
          withLiquidityRider: Math.max(
            el.withLiquidityRider -
              (correspondingSubAgencyCommission?.withLiquidityRider || 0) -
              (correspondingAgentCommission?.withLiquidityRider || 0),
            0,
          ),
        };
      },
    );

    selectedAgencyTable.commission = selectedAgencyTable.commission.map((el) => {
      const correspondingCommissionCalculation = modifiedAgencyCommissionCalculation.find(
        (calculation) => +calculation.year === +el.year,
      );

      const estimatedCommissions = calculateEstimatedCommission(
        el,
        correspondingCommissionCalculation!,
        {
          liquidityRider,
          commissionablePremium,
          excessPremium,
        },
      );

      return { ...el, estimatedCommissions };
    });
  }
  // --------- Task 4 ends here ----------

  // ---------- Task 5 starts here ----------
  // Admin appPercentage should be 100 - sum of all agents app percentage for year 1
  const adminTableIdx = planCommissions?.findIndex((table) => table.userType === "admin");

  const adminTable = planCommissions[adminTableIdx];

  const sumOfAllAgentsPercentage = planCommissions?.reduce((acc, curr) => {
    if (curr.userType === "agent") {
      return acc + curr.commission[0].appPercentage;
    }
    return acc;
  }, 0);

  if (adminTable && adminTable.commission) {
    const adminPercentage = 100 - sumOfAllAgentsPercentage;

    adminTable.commission = adminTable.commission.map((el) => ({
      ...el,
      appPercentage: adminPercentage >= 0 ? adminPercentage : 0,
    }));
  }
  // ---------- Task 5 ends here ----------

  // ---------- Task 6 starts here ----------
  //  calculate estimated commission for the admin
  adminTable.commission = adminTable.commission.map((el) => {
    const correspondingCommissionCalculation = adminTable.commissionCalculation.find(
      (calculation) => +calculation.year === +el.year,
    );

    const estimatedCommissions = calculateEstimatedCommission(
      el,
      correspondingCommissionCalculation!,
      {
        liquidityRider,
        commissionablePremium,
        excessPremium,
      },
    );

    return { ...el, estimatedCommissions };
  });
  // ---------- Task 6 ends here ----------

  return allData;
};

export const preparePlanRiderCommissionTrackingData = (
  allData: any,
  field: string,
  newValue: any,
) => {
  allData.planRider[field] = field === "liquidityRider" ? newValue === "Yes" : newValue;

  const planCommissions: AgentCommissionTracking[] = allData?.planCommissions;

  const agentPlanCommissions = planCommissions.filter((item) => item.userType === "agent");

  let updatedAllData = allData;

  agentPlanCommissions.forEach((item) => {
    updatedAllData = prepareCommissionTrackingData(
      updatedAllData,
      item,
      item.commission[0].appPercentage,
    );
  });

  return updatedAllData;
};

export const updateAgentInCommissionTrackingData = (
  allData: any,
  newData: AgentCommissionTracking[],
  commissionId: string,
) => {
  const planCommissions: AgentCommissionTracking[] = allData?.planCommissions;

  newData = newData.map((el) => ({ ...el, externalAgentName: null }));

  const commissionToReplaceIdx = planCommissions.findIndex((item) => item.id === commissionId);

  const commissionToReplace = planCommissions[commissionToReplaceIdx];

  const prevAppPercentage = commissionToReplace?.commission[0]?.appPercentage;

  const agentFromNewData = newData.find((item) => item.userType === "agent");

  if (!agentFromNewData) {
    return allData;
  }

  if (
    commissionToReplace &&
    !commissionToReplace.userId &&
    commissionToReplace?.ExternalAgentName === notInSystemAgent.NOT_IN_THE_SYSTEM
  ) {
    planCommissions[commissionToReplaceIdx] = {
      ...agentFromNewData,
      ExternalAgentName: null,
      id: commissionToReplace.id,
      commission: commissionToReplace.commission.map((el) => ({
        ...el,
        appPercentage: prevAppPercentage,
      })),
    };
    planCommissions.push(...newData.filter((item) => item.userType !== "agent"));
  }

  if (commissionToReplace && commissionToReplace.userId) {
    planCommissions[commissionToReplaceIdx] = {
      ...agentFromNewData,
      id: commissionToReplace.id,
      commission: commissionToReplace.commission.map((el) => ({
        ...el,
        appPercentage: prevAppPercentage,
      })),
    };

    const { agentId } = commissionToReplace;
    const updatePlanCommissions = planCommissions.filter((el) => el.agentId !== agentId);

    updatePlanCommissions.push(...newData.filter((item) => item.userType !== "agent"));
    allData.planCommissions = updatePlanCommissions;
  }

  if (!agentFromNewData.userId) {
    return allData;
  }

  return prepareCommissionTrackingData(allData, agentFromNewData, prevAppPercentage);
};
