import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const PROPOSED_DISABILITY_POLICY_LEDGER = "PROPOSED_DISABILITY_POLICY_LEDGER";
export const POST_PROPOSED_DISABILITY_POLICY_LEDGER = "POST_PROPOSED_DISABILITY_POLICY_LEDGER";
export const PUT_PROPOSED_DISABILITY_POLICY_LEDGER = "PUT_PROPOSED_DISABILITY_POLICY_LEDGER";
export const DELETE_PROPOSED_DISABILITY_POLICY_LEDGER = "DELETE_PROPOSED_DISABILITY_POLICY_LEDGER";

interface ResponseType {
  data?: any;
  error?: any;
  loading?: boolean;
}

interface ProposedDisabilityPolicyLedger {
  fetchProposedDisabilityPolicyLedgerStart: ResponseType;
  fetchProposedDisabilityPolicyLedgerSuccess: ResponseType;
  fetchProposedDisabilityPolicyLedgerFailure: ResponseType;
  postProposedDisabilityPolicyLedgerStart: ResponseType;
  postProposedDisabilityPolicyLedgerSuccess: ResponseType;
  postProposedDisabilityPolicyLedgerFailure: ResponseType;
  editProposedDisabilityPolicyLedgerStart: ResponseType;
  editProposedDisabilityPolicyLedgerSuccess: ResponseType;
  editProposedDisabilityPolicyLedgerFailure: ResponseType;
  deleteProposedDisabilityPolicyLedgerStart: ResponseType;
  deleteProposedDisabilityPolicyLedgerSuccess: ResponseType;
  deleteProposedDisabilityPolicyLedgerFailure: ResponseType;
}

const initialState: ProposedDisabilityPolicyLedger = {
  fetchProposedDisabilityPolicyLedgerStart: { data: {}, error: {}, loading: false },
  fetchProposedDisabilityPolicyLedgerSuccess: { data: {}, loading: false },
  fetchProposedDisabilityPolicyLedgerFailure: { error: {}, loading: false },
  postProposedDisabilityPolicyLedgerStart: { data: {}, error: {}, loading: false },
  postProposedDisabilityPolicyLedgerSuccess: { data: {}, loading: false },
  postProposedDisabilityPolicyLedgerFailure: { error: {}, loading: false },
  editProposedDisabilityPolicyLedgerStart: { data: {}, error: {}, loading: false },
  editProposedDisabilityPolicyLedgerSuccess: { data: {}, loading: false },
  editProposedDisabilityPolicyLedgerFailure: { error: {}, loading: false },
  deleteProposedDisabilityPolicyLedgerStart: { data: {}, error: {}, loading: false },
  deleteProposedDisabilityPolicyLedgerSuccess: { data: {}, loading: false },
  deleteProposedDisabilityPolicyLedgerFailure: { error: {}, loading: false },
};

export const proposedDisabilityPolicyLedgerSlice = createSlice({
  name: "proposedDisabilityPolicyLedger",
  initialState,
  reducers: {
    fetchProposedDisabilityPolicyLedgerStart: (state) => {
      state.fetchProposedDisabilityPolicyLedgerSuccess = { data: [], error: {}, loading: true };
    },
    fetchProposedDisabilityPolicyLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.fetchProposedDisabilityPolicyLedgerSuccess = { data: action.payload, loading: false };
    },
    fetchProposedDisabilityPolicyLedgerFailure: (state, action: PayloadAction<any>) => {
      state.fetchProposedDisabilityPolicyLedgerSuccess = { data: [], loading: false };
      state.fetchProposedDisabilityPolicyLedgerFailure = { error: action.payload, loading: false };
    },
    postProposedDisabilityPolicyLedgerStart: (state) => {
      state.postProposedDisabilityPolicyLedgerStart = { data: {}, error: {}, loading: true };
    },
    postProposedDisabilityPolicyLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.postProposedDisabilityPolicyLedgerSuccess = { data: action.payload, loading: false };
    },
    postProposedDisabilityPolicyLedgerFailure: (state, action: PayloadAction<any>) => {
      state.postProposedDisabilityPolicyLedgerFailure = { error: action.payload, loading: false };
    },
    editProposedDisabilityPolicyLedgerStart: (state) => {
      state.editProposedDisabilityPolicyLedgerStart = { data: {}, error: {}, loading: true };
    },
    editProposedDisabilityPolicyLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.editProposedDisabilityPolicyLedgerSuccess = { data: action.payload, loading: false };
    },
    editProposedDisabilityPolicyLedgerFailure: (state, action: PayloadAction<any>) => {
      state.editProposedDisabilityPolicyLedgerFailure = { error: action.payload, loading: false };
    },
    deleteProposedDisabilityPolicyLedgerStart: (state) => {
      state.deleteProposedDisabilityPolicyLedgerStart = { data: {}, error: {}, loading: true };
    },
    deleteProposedDisabilityPolicyLedgerSuccess: (state, action: PayloadAction<any>) => {
      state.deleteProposedDisabilityPolicyLedgerSuccess = { data: action.payload, loading: false };
    },
    deleteProposedDisabilityPolicyLedgerFailure: (state, action: PayloadAction<any>) => {
      state.deleteProposedDisabilityPolicyLedgerFailure = { error: action.payload, loading: false };
    },
    resetDisabilityPolicyLedgerState: () => initialState,
    resetDisabilityErrorState: (state) => {
      state.postProposedDisabilityPolicyLedgerStart = { data: {}, error: {}, loading: false };
      state.postProposedDisabilityPolicyLedgerSuccess = { data: {}, loading: false };
      state.postProposedDisabilityPolicyLedgerFailure = { error: {}, loading: false };
    },
    resetEditDisabilityErrorState: (state) => {
      state.editProposedDisabilityPolicyLedgerStart = { data: {}, error: {}, loading: false };
      state.editProposedDisabilityPolicyLedgerFailure = { error: {}, loading: false };
      state.editProposedDisabilityPolicyLedgerSuccess = { data: {}, loading: false };
    },
  },
});

export const {
  fetchProposedDisabilityPolicyLedgerStart,
  fetchProposedDisabilityPolicyLedgerSuccess,
  fetchProposedDisabilityPolicyLedgerFailure,
  postProposedDisabilityPolicyLedgerStart,
  postProposedDisabilityPolicyLedgerSuccess,
  postProposedDisabilityPolicyLedgerFailure,
  editProposedDisabilityPolicyLedgerStart,
  editProposedDisabilityPolicyLedgerSuccess,
  editProposedDisabilityPolicyLedgerFailure,
  deleteProposedDisabilityPolicyLedgerStart,
  deleteProposedDisabilityPolicyLedgerSuccess,
  deleteProposedDisabilityPolicyLedgerFailure,
  resetDisabilityPolicyLedgerState,
  resetDisabilityErrorState,
  resetEditDisabilityErrorState,
} = proposedDisabilityPolicyLedgerSlice.actions;

export const proposedPolicyDisabilityLedgerReducer = (state: RootState) =>
  state.porposedDisabilityPolicyLedger;

export default proposedDisabilityPolicyLedgerSlice.reducer;
