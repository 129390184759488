import { getObjectFromSessionStore, getSessionStore } from "app/utils/sessionStore";
import Sidebar from "./Sidebar";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import { createContext, useEffect, useMemo, useState } from "react";
import { CONTACT_PROFILE_LOOKUP } from "app/redux";
import { getObjectFromLocalStore } from "app/utils/localStore";
import { isEmpty } from "ramda";
import { ProgressSpinner } from "primereact/progressspinner";
import { logoutResponseState } from "app/redux/features/logout";
import ContactLayout from "./ContactLayout";
import { THEME } from "app/utils/types";

export const ThemeContext = createContext<{
  isDarkMode: boolean;
  setIsDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}>({ isDarkMode: false, setIsDarkMode: () => {} });

const RootLayout = () => {
  const lookUpResponse = useSelector((state: RootState) => state?.contactProfileLookup);
  const { isLoggingOut } = useSelector(logoutResponseState);
  const getLookUpResponse = getObjectFromSessionStore("lookUpResponse");
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const userAccess = getObjectFromLocalStore("useraccess");

  const sessionTheme = getSessionStore("globalTheme");
  const isInitiallyDarkModeOn = !(
    sessionTheme === THEME.LIGHT ||
    lookUpResponse?.data?.branding?.theme === THEME.LIGHT ||
    isEmpty(sessionTheme)
  );

  const [isDarkMode, setIsDarkMode] = useState(isInitiallyDarkModeOn);
  useEffect(() => {
    if (lookUpResponse?.data?.branding?.theme) {
      setIsDarkMode(lookUpResponse?.data?.branding?.theme === THEME.DARK);
    }
  }, [lookUpResponse?.data?.branding?.theme]);

  const themeContextValue = useMemo(
    () => ({
      isDarkMode,
      setIsDarkMode,
    }),
    [isDarkMode, setIsDarkMode],
  );

  useEffect(() => {
    if (
      !lookUpResponse.isLoading &&
      pathname !== "/blocked" &&
      !isEmpty(userAccess) &&
      !getLookUpResponse?.menu &&
      !isLoggingOut
    ) {
      dispatch({
        type: CONTACT_PROFILE_LOOKUP,
        payload: {
          address: "lookupAfter",
        },
      });
    }
  }, [pathname, lookUpResponse, getLookUpResponse, isLoggingOut]);

  const sideArr = lookUpResponse?.data?.menu || getLookUpResponse?.menu;
  const sideBarUrls: string[] = sideArr?.reduce(
    (acc: string[], item: any) => acc?.concat(item?.groupItems?.map((it: any) => it?.link)),
    [],
  );
  sideBarUrls?.push(
    "/profile",
    "/chat",
    "/notification",
    "/settings",
    "/agency",
    "/sub-agency",
    "/agent",
    "/subagencymanager",
    "/subagencyagents",
    "/subagencyassistants",
    "/assistant",
    "/requestProposal",
    "/confirmDetails",
    "/editProfile",
    "/agentsubagencyassistants",
    "/agentsubagencyagents",
    "/agentsubagencymanager",
    "/loans",
    "/dashboard",
  );

  const currentUrlStartWith = pathname.split("/")[1];

  const idx = sideBarUrls?.findIndex((el) => currentUrlStartWith === el.slice(1));

  return (
    <ThemeContext.Provider value={themeContextValue}>
      {!sideArr?.length && lookUpResponse.isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="4"
            fill="transparent"
            animationDuration=".5s"
            className="p-progress-circle"
          />
        </div>
      ) : idx >= 0 ? (
        userAccess?.userType === "contact" ? (
          <ContactLayout />
        ) : (
          <>
            <div className="h-full flex">
              <Sidebar />
              <div className="flex-1 overflow-auto h-screen bg-[var(--surface-ground)]">
                <Outlet />
              </div>
            </div>
          </>
        )
      ) : (
        <Outlet />
      )}
    </ThemeContext.Provider>
  );
};

export default RootLayout;
