import { v4 as uuidv4 } from "uuid";
import { ColumnJson, DataTableDataInterface } from "app/utils/types";

export const editableColumns: string[] = [
  "employeePremium",
  "employerPremium",
  "employerAdminFee",
  "planOriginationFees",
  "planLoanRate",
  "minimumAFR",
  "employeePrincipalPayment",
];

export const planAdminSplitDollarTableDataJson: DataTableDataInterface = {
  pagination: 10,
  rowsPerPage: [25, 50, 100],
  styles: {
    minWidth: "95rem",
    minHeight: "",
  },
  scrollable: true,
  scrollHeight: "500px",
  onRowClickNavigateTo: "/agent/contact",
  column: [],
};
export const planAdminSplitDollarColumnSchema = (
  label: string,
  field: string,
  isFrozen = false,
  classNames?: string,
): ColumnJson => ({
  id: `${field}-${uuidv4()}`,
  body: {},
  field,
  label,
  frozen: isFrozen,
  header: false,
  filters: {
    filterType: "text",
    value: "",
  },
  editColumn: editableColumns.findIndex((col) => col === field) > -1,
  align: "right",
  alignHeader: "center",
  editorType: "decimal",
  classNames,
});

export const headerColumnGroup = {
  EOP: ["employeePremium", "employeeInterestPayment", "employeeOOP"],
  EOPP: ["employerPremium", "employerAdminFee", "planOriginationFees", "employerOOP"],
  TPPIC: ["totalPolicyPremium"],
  "INR%": ["planLoanRate", "minimumAFR"],
  INR$: ["planInterest", "minimumAFRInterest"],
  IC: ["imputedCompensationToEmployee"],
  OB: ["boyOutstandingLoanBalance", "eoyOutstandingLoanBalance"],
  EPP: ["employeePrincipalPayment"],
};

export const commonColumns = ["policyYear", "insuredAge", "planYear"];

export const prepareRows = (
  rowData: any,
  key: string,
  value: number,
  rowIdx: number,
  planSDSchema: any,
  policyInputCSVData: any,
  accrueInterest: boolean,
) => {
  const data = rowData[rowIdx];

  const adminSplitDollarRow = { ...data };

  adminSplitDollarRow[key] = value;

  const prevRowData = rowData.find(
    (row: any) =>
      +row.insuredAge === +adminSplitDollarRow.insuredAge - 1 &&
      +row.policyYear === +adminSplitDollarRow.policyYear - 1 &&
      +row.planYear === +adminSplitDollarRow.planYear - 1,
  );

  adminSplitDollarRow.boyOutstandingLoanBalance =
    +adminSplitDollarRow.employerPremium +
    +(prevRowData ? prevRowData.eoyOutstandingLoanBalance : 0);

  adminSplitDollarRow.planInterest =
    (+adminSplitDollarRow.planLoanRate / 100) * +adminSplitDollarRow.boyOutstandingLoanBalance;

  if (accrueInterest) {
    adminSplitDollarRow.employeeInterestPayment = 0;
  } else {
    adminSplitDollarRow.employeeInterestPayment = +adminSplitDollarRow.planInterest;
  }

  adminSplitDollarRow.employeeOOP =
    +adminSplitDollarRow.employeePremium + +adminSplitDollarRow.employeeInterestPayment;

  adminSplitDollarRow.employerOOP =
    +adminSplitDollarRow.employerPremium +
    +adminSplitDollarRow.employerAdminFee +
    +adminSplitDollarRow.planOriginationFees;

  adminSplitDollarRow.totalPolicyPremium =
    +adminSplitDollarRow.employerPremium + +adminSplitDollarRow.employeePremium;

  adminSplitDollarRow.minimumAFRInterest =
    (+adminSplitDollarRow.minimumAFR / 100) * +adminSplitDollarRow.boyOutstandingLoanBalance;

  if (accrueInterest) {
    adminSplitDollarRow.imputedCompensationToEmployee = 0;
  } else {
    adminSplitDollarRow.imputedCompensationToEmployee = Math.max(
      +adminSplitDollarRow.minimumAFRInterest - +adminSplitDollarRow.employeeInterestPayment,
      0,
    );
  }

  adminSplitDollarRow.eoyOutstandingLoanBalance = Math.max(
    +adminSplitDollarRow.boyOutstandingLoanBalance - +adminSplitDollarRow.employeePrincipalPayment,
    0,
  );
  if (accrueInterest) {
    adminSplitDollarRow.eoyOutstandingLoanBalance += Math.max(
      +adminSplitDollarRow.minimumAFRInterest,
      +adminSplitDollarRow.planInterest,
    );
  }

  rowData[rowIdx] = adminSplitDollarRow;

  for (let i = rowIdx + 1; i < rowData.length; i++) {
    rowData[i].boyOutstandingLoanBalance =
      +rowData[i].employerPremium + +rowData[i - 1].eoyOutstandingLoanBalance;

    rowData[i].planInterest =
      (+rowData[i].planLoanRate / 100) * +rowData[i].boyOutstandingLoanBalance;

    rowData[i].minimumAFRInterest =
      (+rowData[i].minimumAFR / 100) * +rowData[i].boyOutstandingLoanBalance;

    rowData[i].eoyOutstandingLoanBalance = Math.max(
      +rowData[i].boyOutstandingLoanBalance - +rowData[i].employeePrincipalPayment,
      0,
    );
    if (accrueInterest) {
      rowData[i].eoyOutstandingLoanBalance += Math.max(
        +rowData[i].minimumAFRInterest,
        +rowData[i].planInterest,
      );
    }
  }

  const keys1 = Object.keys(planSDSchema);
  const keys2 = Object.keys(adminSplitDollarRow);

  const commonKeys = keys1.filter((k) => keys2.includes(k));

  let splitDollarRow = commonKeys.reduce(
    (acc, k) => ({
      ...acc,
      [k]: adminSplitDollarRow[k],
    }),
    {},
  );

  splitDollarRow = {
    ...splitDollarRow,
    endOfYearOutstandingLoanBalance: adminSplitDollarRow.eoyOutstandingLoanBalance,
  };

  const splitDollarRowArr = [splitDollarRow];

  for (let i = rowIdx + 1; i < rowData.length; i++) {
    const newRow = commonKeys.reduce(
      (acc, k) => ({
        ...acc,
        [k]: rowData[i][k],
      }),
      {},
    );
    splitDollarRowArr.push({
      ...newRow,
      endOfYearOutstandingLoanBalance: rowData[i].eoyOutstandingLoanBalance,
    });
  }

  return {
    adminSplitDollarRow,
    splitDollarRowArr,
    updatedRows: rowData,
  };
};
