import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export const GET_VIEW_COMMISSION_TRACKING = "GET_VIEW_COMMISSION_TRACKING";
export const UPDATE_COMMISSION_TRACKING = "UPDATE_COMMISSION_TRACKING";
export const UPDATE_AGENT_COMMISSION_TRACKING = "UPDATE_AGENT_COMMISSION_TRACKING";
export const UPDATE_PLAN_RIDER_COMMISSION_TRACKING = "UPDATE_PLAN_RIDER_COMMISSION_TRACKING";

const initialState = {
  loading: false,
  data: {} as any,
  error: {} as any,
};

export const getViewCommissionTrackingSlice = createSlice({
  name: "getViewCommissionTracking",
  initialState,
  reducers: {
    getViewCommissionTrackingStart: (state) => {
      // state.data = {};
      state.error = {};
      state.loading = true;
    },
    getViewCommissionTrackingSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    getViewCommissionTrackingError: (state, action: PayloadAction<any>) => {
      state.data = {};
      state.error = action.payload;
      state.loading = false;
    },
    updateCommissionTrackingSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.error = {};
      state.loading = false;
    },
    resetGetViewCommissionTrackingState: () => initialState,
  },
});

export const {
  getViewCommissionTrackingStart,
  getViewCommissionTrackingSuccess,
  getViewCommissionTrackingError,
  resetGetViewCommissionTrackingState,
  updateCommissionTrackingSuccess,
} = getViewCommissionTrackingSlice.actions;

export const getViewCommissionTracking = (state: RootState) => state.getViewCommissionTracking;
export default getViewCommissionTrackingSlice.reducer;
