import {
  prepareCommissionTrackingData,
  preparePlanRiderCommissionTrackingData,
  updateAgentInCommissionTrackingData,
} from "app/Data/PlanComissionTrackerJson";
import {
  getViewPolicyDetailsStart,
  getViewPolicyDetailsSuccess,
  getViewPolicyDetailsError,
  GET_VIEW_POLICY_DETAILS,
  putViewPolicyDetailsStart,
  putViewPolicyDetailsSuccess,
  putViewPolicyDetailsError,
  PUT_VIEW_POLICY_DETAILS,
  getViewCommissionTrackingStart,
  getViewCommissionTrackingSuccess,
  getViewCommissionTrackingError,
  GET_VIEW_COMMISSION_TRACKING,
  saveViewCommissionTrackingStart,
  saveViewCommissionTrackingSuccess,
  saveViewCommissionTrackingError,
  SAVE_VIEW_COMMISSION_TRACKING,
  getViewLoanDetailsStart,
  getViewLoanDetailsSuccess,
  getViewLoanDetailsError,
  GET_VIEW_LOAN_DETAILS,
  putViewLoanDetailsStart,
  putViewLoanDetailsSuccess,
  putViewLoanDetailsError,
  PUT_VIEW_LOAN_DETAILS,
} from "app/redux";
import {
  getViewCommissionTracking,
  UPDATE_AGENT_COMMISSION_TRACKING,
  UPDATE_COMMISSION_TRACKING,
  UPDATE_PLAN_RIDER_COMMISSION_TRACKING,
  updateCommissionTrackingSuccess,
} from "app/redux/features/view-policy-details/comissionTracking";

import { makeRequest } from "app/utils/makeRequest";
import { clone } from "ramda";

import { all, call, put, select, takeLatest } from "redux-saga/effects";

function* getViewPolicyDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getViewPolicyDetailsStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getViewPolicyDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(getViewPolicyDetailsError(error));
  }
}

function* putViewPolicyDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(putViewPolicyDetailsStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(putViewPolicyDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(putViewPolicyDetailsError(error));
  }
}
function* getViewCommissionTrackingRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams, isAdvisorRemoved } = payload;
    yield put(getViewCommissionTrackingStart());
    let response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));

    // if advisor is removed, then update the admin commission which is
    // 100 - sum of all agent commissions for year 1, then save the data and get the updated data
    if (isAdvisorRemoved) {
      const firstAgent = response.data?.data?.planCommissions?.find(
        (el: any) => el.userType === "agent",
      );

      const data = prepareCommissionTrackingData(
        clone(response.data?.data),
        firstAgent,
        firstAgent?.commission?.[0]?.appPercentage,
      );

      yield call(() => makeRequest(address).put(key, data, requestParams, queryParams));

      response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    }
    yield put(getViewCommissionTrackingSuccess(response.data));
  } catch (error: any) {
    yield put(getViewCommissionTrackingError(error));
  }
}

function* updateCommissionTrackingRequest({ payload }: any): Generator<any, void, any> {
  const store = yield select(getViewCommissionTracking);

  const data = prepareCommissionTrackingData(
    clone(store?.data?.data),
    payload.data,
    payload.newValue,
  );

  yield put(updateCommissionTrackingSuccess({ data }));
}

function* updatePlanRiderCommissionTrackingRequest({ payload }: any): Generator<any, void, any> {
  const store = yield select(getViewCommissionTracking);

  const data = preparePlanRiderCommissionTrackingData(
    clone(store?.data?.data),
    payload.field,
    payload.newValue,
  );

  yield put(updateCommissionTrackingSuccess({ data }));
}

function* updateAgentCommissionTracking({ payload }: any): Generator<any, void, any> {
  const store = yield select(getViewCommissionTracking);

  const data = updateAgentInCommissionTrackingData(
    clone(store?.data?.data),
    payload.data,
    payload.commissionId,
  );

  yield put(updateCommissionTrackingSuccess({ data }));
}

function* putSaveCommissionTrackingRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(saveViewCommissionTrackingStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(saveViewCommissionTrackingSuccess(response.data));
  } catch (error: any) {
    yield put(saveViewCommissionTrackingError(error));
  }
}

function* getViewLoanDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, requestParams, queryParams } = payload;
    yield put(getViewLoanDetailsStart());
    const response = yield call(() => makeRequest(address).get(key, requestParams, queryParams));
    yield put(getViewLoanDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(getViewLoanDetailsError(error));
  }
}

function* putViewLoanDetailsRequest({ payload }: any): Generator<any, void, any> {
  try {
    const { address, key, body, requestParams, queryParams } = payload;
    yield put(putViewLoanDetailsStart());
    const response = yield call(() =>
      makeRequest(address).put(key, body, requestParams, queryParams),
    );
    yield put(putViewLoanDetailsSuccess(response.data));
  } catch (error: any) {
    yield put(putViewLoanDetailsError(error));
  }
}
function* watchViewPolicyDetailsSagas() {
  yield takeLatest(GET_VIEW_POLICY_DETAILS, getViewPolicyDetailsRequest);
  yield takeLatest(PUT_VIEW_POLICY_DETAILS, putViewPolicyDetailsRequest);
  yield takeLatest(GET_VIEW_LOAN_DETAILS, getViewLoanDetailsRequest);
  yield takeLatest(PUT_VIEW_LOAN_DETAILS, putViewLoanDetailsRequest);
  yield takeLatest(GET_VIEW_COMMISSION_TRACKING, getViewCommissionTrackingRequest);
  yield takeLatest(SAVE_VIEW_COMMISSION_TRACKING, putSaveCommissionTrackingRequest);
  yield takeLatest(UPDATE_COMMISSION_TRACKING, updateCommissionTrackingRequest);
  yield takeLatest(UPDATE_PLAN_RIDER_COMMISSION_TRACKING, updatePlanRiderCommissionTrackingRequest);
  yield takeLatest(UPDATE_AGENT_COMMISSION_TRACKING, updateAgentCommissionTracking);
}

export default function* viewPolicyDetailsSagas() {
  yield all([watchViewPolicyDetailsSagas()]);
}
